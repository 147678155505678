import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import InformationLayout from "../../layouts/InformationLayout.jsx";
import indexData from "./index.json";
import Downloads from "../../components/_ui/Downloads.jsx";
export const _frontmatter = {
  "title": "Pergola Planning - Tools and Materials required"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  pagesList
}) => <InformationLayout pagesList={indexData.pageIndex}>
    {children}
  </InformationLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Tools and Materials required`}</h1>
    <p><strong parentName="p">{`Once you have purchased your pergola, it will be delivered with all the fixings, brackets and ground support posts that are required for easy assembly. However, please find below a list of tools and materials that we recommend in order for you to successfully construct your pergola and ensure many years of happy outdoor living.`}</strong></p>
    <p>{`No doubt most of you you will have these at home anyway but a trip to your local builder’s merchant, garden centre or hardware outlet may be necessary to complete the list:`}</p>
    <h2>{`Tools:`}</h2>
    <ol>
      <li parentName="ol">{`Cordless Combi Drill (wood/masonry)`}</li>
      <li parentName="ol">{`Set of drill bits for above – both wood and masonry`}</li>
      <li parentName="ol">{`Adjustable spanner`}</li>
      <li parentName="ol">{`Hand saw (only required if you are customizing your pergola)`}</li>
      <li parentName="ol">{`Screwdriver set`}</li>
      <li parentName="ol">{`Tape measure`}</li>
      <li parentName="ol">{`Spirit level/Plumb line`}</li>
      <li parentName="ol">{`Builders Square`}</li>
      <li parentName="ol">{`Mallet/Sledgehammer (if using the ‘original’ spiked post fix system)`}</li>
      <li parentName="ol">{`Spade (if using the ‘concrete-in’ post fix system)`}</li>
      <li parentName="ol">{`Step Ladder and Workbench`}</li>
    </ol>
    <h2>{`Materials:`}</h2>
    <ol>
      <li parentName="ol">{`Quick drying concrete mix (needed for the ‘concrete-in’ post fix system))`}</li>
      <li parentName="ol">{`Wood preservative - if you have to cut any of the timbers to fit to your own requirements`}</li>
    </ol>
    <h2>{`Downloads & Support`}</h2>
    <Downloads files={[{
      filename: "Pergola assembly guide",
      path: "/downloads/ASSEMBLY.pdf"
    }, {
      filename: "Pergola wood treatment information",
      path: "/downloads/EN-Wolmanit_CX_enduser_022010.pdf"
    }]} mdxType="Downloads" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      